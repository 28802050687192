import React, { useState } from 'react';
import { Popover } from 'antd';
import { TrashOutlineIcon } from '../../../../../../../icons';
import { Colors } from '../../../../../../../constants/colors.constants.js';

export const DependenciesAlertPopover = ({ onClickButtonDelete, t }) => {
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (newVisible) => setVisible(newVisible);

  const deletePopover = () => onClickButtonDelete();

  const content = (
    <div className="alert-popover">
      <h6>{t('activity_card.title_popover_remove_task')}</h6>
      <div className="alert-popover__buttons">
        <button
          className="buttons__cancel"
          onClick={() => handleVisibleChange(false)}>
          {t('activity_card.text_button_cancel_remove_task')}
        </button>
        <button className="buttons__delete" onClick={() => deletePopover()}>
          {t('activity_card.text_button_ok_remove_task')}
        </button>
      </div>
    </div>
  );

  return (
    <Popover
      visible={visible}
      onVisibleChange={handleVisibleChange}
      content={content}
      trigger="click"
      overlayClassName="overlay-alert-popover">
      <div style={{ cursor: 'pointer' }}>
        <TrashOutlineIcon color={Colors.WHITE} className="trash-icon" />
      </div>
    </Popover>
  );
};

export default DependenciesAlertPopover;
