import * as icons from '../../../icons';

export const LOOKAHEAD_RANGE = (t) => [
  {
    icon: icons.CalendarSolidIcon,
    tooltipI18nKey: 'filters_label.lookahead_label',
    preventCloseDropDown: false,
    subitemsSettings: [
      {
        i18nKey: 'activity_tree_panel.show_all',
        command: 'CLEAR_RANGE',
        checkable: true
      },
      {
        i18nKey: 'filters_label.lookahead_free',
        command: 'FREE_RANGE',
        checkable: true
      },
      {
        i18nKey: 'filters_label.lookahead_this_week',
        command: '1_WEEKS',
        checkable: true
      },
      {
        text: `2 ${t('filters_label.lookahead_plural_week')}`,
        command: '2_WEEKS',
        checkable: true
      },
      {
        text: `3 ${t('filters_label.lookahead_plural_week')}`,
        command: '3_WEEKS',
        checkable: true
      },
      {
        text: `4 ${t('filters_label.lookahead_plural_week')}`,
        command: '4_WEEKS',
        checkable: true
      },
      {
        text: `5 ${t('filters_label.lookahead_plural_week')}`,
        command: '5_WEEKS',
        checkable: true
      },
      {
        text: `6 ${t('filters_label.lookahead_plural_week')}`,
        command: '6_WEEKS',
        checkable: true
      },
      {
        text: `7 ${t('filters_label.lookahead_plural_week')}`,
        command: '7_WEEKS',
        checkable: true
      },
      {
        text: `8 ${t('filters_label.lookahead_plural_week')}`,
        command: '8_WEEKS',
        checkable: true
      }
    ]
  }
];
