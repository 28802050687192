import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
  activityUpdateRequested,
  activityCardUpdateLocally
} from '../../../redux/slices/activitySlice';
import { AssigneesPicker } from '../../AssigneesPicker';
import { EditableLabel } from '../../EditableLabel';
import {
  getChartColor,
  getChartLabel,
  updateGanttLocally,
  mapAssigneesToNewFormat,
  setChartColorCustomProperty
} from '../utils';
import styles from './GeneralInfoPanel.module.scss';
import { SubContractDropdown } from './SubContractDropdown';
import { TagDropdown } from './TagDropdown';
import { TimerManagerSingleton } from '../../../utils/timerManager';

export const GeneralInfoPanel = ({
  cardData,
  activityUpdateRequested,
  activityCardUpdateLocally,
  t,
  currentTasks
}) => {
  const completion = cardData.completion || 0;

  useEffect(() => {
    setChartColorCustomProperty(getChartColor(completion));

    return () => {
      setChartColorCustomProperty('');
    };
  }, [completion]);

  /**
   * This function evaluates if the progress of the activity can be edited.
   * Depending on whether it has tasks and whether it has secondary activities
   * @param {*} item activity object to be edited
   * @returns boolean indicating whether or not the activity can be edited
   */
  const canBeActivityEdited = (item) => {
    const isObject = Object.keys(item).length !== 0;
    let canBeEdit = false;

    if (isObject) {
      if (currentTasks.length > 0) return false;
      if (item.hasChilds) return false;
      canBeEdit = true;
    }
    return canBeEdit;
  };
  return (
    <div className={styles['general-info-panel']}>
      <EditableLabel
        initialValue={cardData.title}
        className={styles['general-info-panel__title']}
        onChange={(newTitle) => {
          const gantt = window.to_use_react_gantt;
          updateGanttLocally(cardData, 'text', newTitle, gantt);

          /** update reference */
          activityCardUpdateLocally({
            ...cardData,
            name: newTitle
          });
        }}
      />
      <div className={styles['general-info-panel__progress']}>
        <div className={styles['general-info-panel__progress-chart']}>
          <div className={styles['general-info-panel__progress-chart-inner']} />
          <div
            className={styles['general-info-panel__progress-chart-bottom-half']}
          />
          <div
            className={styles['general-info-panel__progress-chart-top-half']}
            style={{ transform: `rotate(${(completion / 100) * 180}deg)` }}>
            <span
              className={
                styles['general-info-panel__progress-chart-top-half-corner']
              }
            />
          </div>
        </div>
        <span className={styles['general-info-panel__progress-amount']}>
          <EditableLabel
            inputConfig={{
              type: 'number',
              min: 0,
              max: 100,
              noSpin: true
            }}
            initialValue={completion}
            renderer={(value) => `${+parseFloat(value).toFixed(2)}%`}
            onChange={(progress) => {
              const gantt = window.to_use_react_gantt;
              updateGanttLocally(cardData, 'progress', progress, gantt);
              gantt.update_progress(cardData.uniqueId);
              const timerManager = TimerManagerSingleton.getInstance();
              timerManager.clearTimer(`progressTimer-${cardData.id}`);
              const renderCallback = () => {
                gantt.render();
              };
              timerManager.registerAutoTimeout(
                renderCallback,
                200,
                `progressTimer-${cardData.id}`
              );
            }}
            enableEditing={canBeActivityEdited(cardData)}
            inputClassName={
              styles['general-info-panel__progress-amount-editable-label']
            }
          />
        </span>
        <span className={styles['general-info-panel__progress-description']}>
          {getChartLabel(completion)}
        </span>
      </div>
      <div className={styles['general-info-panel__breadcrumb']}>
        <span>{cardData.activityRoute}</span>
      </div>
      <div className={styles['general-info-panel__breadcrumb']}></div>
      <div className={styles['general-info-panel__assignee']}>
        <span className={styles['general-info-panel__label']}>
          {t('master_plan.responsible')}
        </span>
        {cardData.originalActivityObject && (
          <div className={styles['general-info-panel__assignee-picker']}>
            <AssigneesPicker
              selectedUsers={cardData.assignees ?? []}
              entity={cardData.originalActivityObject}
              displayNameIfSingleUser={true}
              onUpdated={(originalActivityObject) =>
                activityCardUpdateLocally({
                  ...cardData,
                  assignees: mapAssigneesToNewFormat(
                    originalActivityObject.responsables
                  )
                })
              }
            />
          </div>
        )}
      </div>
      <div className={styles['general-info-panel__subtrade']}>
        <span className={styles['general-info-panel__label']}>
          {t('subcontract_only_label')}
        </span>
        {
          <SubContractDropdown
            activityCardUpdateLocally={activityCardUpdateLocally}
            activityUpdateRequested={activityUpdateRequested}
            t={t}
            cardData={cardData}
          />
        }
      </div>
      <div className={styles['general-info-panel__tags']}>
        <span className={styles['general-info-panel__label']}>Tags</span>
        {
          <TagDropdown
            activityCardUpdateLocally={activityCardUpdateLocally}
            activityUpdateRequested={activityUpdateRequested}
            t={t}
            cardData={cardData}
          />
        }
      </div>
    </div>
  );
};

const actionCreators = {
  activityUpdateRequested,
  activityCardUpdateLocally
};

export default connect(null, actionCreators)(GeneralInfoPanel);
