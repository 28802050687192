import React, { useEffect, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {
  getActivityCardDataSelector,
  getActivityLogDataSelector,
  activityCardResetData
} from '../../redux/slices/activitySlice';
import {
  activityCardFetchRequested,
  activityLogFetchRequested
} from '../../redux/slices/activitySlice';
import { Drawer } from '../Drawer';
import { activityService } from '../../services';
import { ActivityLog } from './components/ActivityLog';
import GeneralInfoPanel from './components/GeneralInfoPanel';
import Tabs from './components/Tabs';
import { mapTasksToNewFormat } from './utils';
import styles from './ActivityCardDrawer.module.scss';
import { getGanttDateFormatSelector } from '../../redux/slices/ganttSlice';
import {
  refreshCalendars,
  setCustomHourFn
} from '../../views/ganttContainer/gantt/gantt.helper';
import { procoreService } from '../../services/procore.service';
import { getSectorCompanyAndProject } from '../../analytics/utils';
import { TimerManagerSingleton } from '../../utils/timerManager';

export const ActivityCardDrawer = ({
  cardData,
  logData,
  drawerConfig,
  params,
  t,
  dateFormat,
  ...actions
}) => {
  const {
    activityCardFetchRequested,
    activityLogFetchRequested,
    activityCardResetData
  } = actions;
  const { globalActivityId, globalDhtmlxId } = params;
  const [hasFinishedInitialLoading, setHasFinishedInitialLoading] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentCardData, setCurrentCardData] = useState(cardData);
  const [currentLogData, setCurrentLogData] = useState(logData);
  const [currentTasks, setCurrentTasks] = useState([]);
  const [calendars, setCalendars] = useState(null);
  const [customHour, setCustomHour] = useState({ startHour: '', endHour: '' });
  const drawerContainerRef = useRef();
  const [baseUrl, setBaseUrl] = useState(null);
  const { currentSector, currentProject } = getSectorCompanyAndProject();

  useEffect(() => {
    setHasFinishedInitialLoading(false);
    setIsLoading(true);
    activityCardFetchRequested({ globalActivityId, globalDhtmlxId });
    activityLogFetchRequested({ globalActivityId, globalDhtmlxId });
  }, [params, dateFormat]);

  useEffect(() => {
    const getProcoreBaseUrl = async () => {
      const response = await procoreService.getProcoreBaseUrl(
        currentProject?.id,
        currentSector?.id
      );
      if (response?.webBaseUrl) {
        setBaseUrl(response.webBaseUrl);
      }
    };

    try {
      getProcoreBaseUrl();
    } catch (error) {
      setBaseUrl('');
    }
  }, [globalActivityId]);

  useEffect(() => {
    if (globalActivityId) {
      async function fetchData() {
        const responseTasks = await getTasks();
        if (responseTasks.status === 200) {
          const filterTasks = responseTasks.tasks.filter(
            (task) => task.parent_id === null || task.parent_id === 0
          );
          setCurrentTasks(mapTasksToNewFormat(filterTasks, dateFormat));
        }
      }

      fetchData();
    }
  }, [globalActivityId]);

  useEffect(() => {
    drawerContainerRef.current && drawerContainerRef.current.focus();
  }, [globalActivityId]);

  /** use effect to set hours according shifts */
  useEffect(() => {
    if (!calendars) return;
    setCustomHourFn(calendars, customHour, setCustomHour);
  }, [calendars]);

  const calendarObject = {
    calendars,
    customHour
  };

  useEffect(() => {
    if (!cardData || !logData) return;
    setCurrentCardData(cardData);
    setCurrentLogData(logData);

    if (hasFinishedInitialLoading) return;
    setIsLoading(false);
    setHasFinishedInitialLoading(true);
    refreshCalendars(cardData.originalActivityObject.sectorId, setCalendars);
  }, [cardData, logData]);

  const getTasks = async () => {
    try {
      const tasks = await activityService.getTasksActivity(globalActivityId);
      return tasks;
    } catch (err) {
      console.log(`Err in getTasks(): ${err}`);
    }
  };

  const onDrawerClose = () => {
    if (window.to_use_react_gantt) {
      const timerManager = TimerManagerSingleton.getInstance();
      const renderCallback = () => {
        window.to_use_react_gantt.render();
      };
      timerManager.registerAutoTimeout(renderCallback, 100, 'onDrawerClose');
    }
    activityCardResetData();
  };

  return (
    <Drawer
      config={{ ...drawerConfig, loading: isLoading }}
      onClose={onDrawerClose}
      className={styles['activity-card-drawer']}
      contentClassName={styles['activity-card-drawer__content']}
      containerRef={drawerContainerRef}>
      <GeneralInfoPanel
        cardData={currentCardData || {}}
        setIsLoading={setIsLoading}
        t={t}
        currentTasks={currentTasks}
      />
      <Tabs
        t={t}
        cardData={currentCardData || {}}
        setIsLoading={setIsLoading}
        setCurrentCardData={setCurrentCardData}
        calendarObject={calendarObject}
        currentTasks={currentTasks}
        procoreBaseUrl={baseUrl}
      />
      <ActivityLog t={t} logData={currentLogData || []} />
    </Drawer>
  );
};

const actionCreators = {
  activityCardFetchRequested,
  activityLogFetchRequested,
  activityCardResetData
};

const mapStateToProps = (state) => ({
  cardData: getActivityCardDataSelector(state),
  logData: getActivityLogDataSelector(state),
  dateFormat: getGanttDateFormatSelector(state)
});

export default connect(
  mapStateToProps,
  actionCreators
)(withTranslation()(ActivityCardDrawer));
